import "./App.css";
import React, { Component } from "react";
import axios from "axios";
import config from "./config.js";
import {
  Col,
  Container,
  Card,
  Row,
  Table,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { Bracket } from "react-brackets";

class Standings extends Component {
  constructor(props) {
    super(props);
    document.title = "The Real League";

    this.state = {
      isLoading: true,
      loadFailed: false,
      standings: [],
    };
  }

  componentDidMount() {
    axios
      .get(config.apiGateway.URL + "standings")
      .then((res) => {
        const standings = Object.entries(res.data).map((item) => item[1]);
        standings.sort(function (a, b) {
          return a.wins < b.wins ? 1 : -1;
        });
        this.setState({
          standings: standings,
          isLoading: false,
          loadFailed: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          loadFailed: true,
        });
      });
  }

  render() {
    return (
      <Card className="mt-10">
        <Card.Title>Real League Standings</Card.Title>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>Team</th>
                <th>Wins</th>
                <th>Losses</th>
                <th>Win Percentage</th>
                <th>Total Points</th>
                <th>Auto Losses</th>
              </tr>
            </thead>
            <tbody>
              {this.state.standings.map((team) => {
                return (
                  <tr key={team.name}>
                    <td>{team.name}</td>
                    <td>{team.wins}</td>
                    <td>{team.losses}</td>
                    <td>
                      {(team.wins / (team.wins + team.losses)).toPrecision(3)}
                    </td>
                    <td>{team.points.toFixed(2)}</td>
                    <td>{team.auto_losses}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {this.state.isLoading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {this.state.loadFailed &&
            "Could not load league data. Please refresh the page."}
        </Card.Body>
      </Card>
    );
  }
}

class Matchups extends Component {
  constructor(props) {
    super(props);
    document.title = "The Real League";

    this.state = {
      isLoading: true,
      loadFailed: false,
      matchups: [],
      week: null,
      current_week: 1,
    };
  }

  componentDidMount() {
    this.getScores();
  }

  getScores() {
    var query = this.state.week === null ? "" : "?week=" + this.state.week;
    console.log(query);
    axios
      .get(config.apiGateway.URL + "scores" + query)
      .then((res) => {
        const matchups = res.data.matchups;
        this.setState({
          matchups: matchups,
          week: res.data.week,
          current_week: res.data.current_week,
          isLoading: false,
          loadFailed: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          loadFailed: true,
        });
      });
  }

  render() {
    var items = Array(this.state.current_week)
      .fill(1)
      .map((x, y) => x + y);
    return (
      <Card>
        <Card.Title>Matchups</Card.Title>
        <Card.Body>
          <Pagination className="justify-content-md-center">
            {items.map((item) => {
              return (
                <Pagination.Item
                  key={item}
                  activeLabel=""
                  active={item === this.state.week}
                  onClick={() => {
                    this.setState(
                      { week: item, isLoading: true },
                      this.getScores
                    );
                  }}
                >
                  {item}
                </Pagination.Item>
              );
            })}
          </Pagination>
          {this.state.isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            this.state.matchups.map((matchup) => {
              return (
                <div key={matchup[0].name}>
                  {matchup[0].name} ({matchup[0].team_score.toFixed(2)}) vs.{" "}
                  {matchup[1].name} ({matchup[1].team_score.toFixed(2)})
                </div>
              );
            })
          )}
          {this.state.loadFailed &&
            "Could not load league data. Please refresh the page."}
        </Card.Body>
      </Card>
    );
  }
}

class Tournament extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      loadFailed: false,
      matchups: [],
      rounds: [],
    };
  }

  componentDidMount() {
    this.getTournament();
  }

  getTournament() {
    axios
      .get(config.apiGateway.URL + "tournament")
      .then((res) => {
        const matchups = res.data;
        const rounds = [
          {
            title: "Final Four",
            seeds: [
              {
                id: 1,
                teams: [
                  {
                    name: `(${matchups[0].teams[0].seed}) ${matchups[0].teams[0].team_name} - ${matchups[0].teams[0].stat} INTs - ${matchups[0].teams[0].score}`,
                  },
                  {
                    name: `(${matchups[0].teams[1].seed}) ${matchups[0].teams[1].team_name} - ${matchups[0].teams[1].stat} INTs - ${matchups[0].teams[1].score}`,
                  },
                ],
              },
              {
                id: 2,
                teams: [
                  {
                    name: `(${matchups[1].teams[0].seed}) ${matchups[1].teams[0].team_name} - ${matchups[1].teams[0].stat} INTs - ${matchups[1].teams[0].score}`,
                  },
                  {
                    name: `(${matchups[1].teams[1].seed}) ${matchups[1].teams[1].team_name} - ${matchups[1].teams[1].stat} INTs - ${matchups[1].teams[1].score}`,
                  },
                ],
              },
            ],
          },
        ];
        this.setState({
          matchups: matchups,
          rounds: rounds,
          isLoading: false,
          loadFailed: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
          loadFailed: true,
        });
      });
  }

  render() {
    return (
      <Card className="mt-10">
        <Card.Title>Gordie O'Keke Memorial Tournament</Card.Title>
        <Card.Body>
          {this.state.isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Bracket rounds={this.state.rounds} />
          )}
          {this.state.loadFailed &&
            "Could not load league data. Please refresh the page."}
        </Card.Body>
      </Card>
    );
  }
}

class Home extends Component {
  constructor(props) {
    super(props);
    document.title = "The Real League";

    this.state = {
      isLoading: true,
      loadFailed: false,
      standings: [],
      matchups: [],
    };
  }

  render() {
    return (
      <Container>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <Standings />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Matchups />
          </Col>
          <Col className="mt-10" sm={12} md={12} lg={12}>
            <Tournament className="mt-10" />
          </Col>
        </Row>
      </Container>
    );
  }
}

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
